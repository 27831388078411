var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("div", { staticClass: "wrapper__card" }, [
        _c(
          "div",
          { staticClass: "wrapper__card__stepper" },
          [
            _c(
              "div",
              { staticClass: "wrapper__card__stepper__zstepper" },
              [
                _c("ZStepperMobileVue", {
                  staticClass: "show-on-mobile",
                  attrs: {
                    steppers: _vm.steppers,
                    "current-stepper": _vm.stepperValue,
                  },
                }),
                _c("z-stepper", {
                  staticClass: "hide-on-mobile",
                  attrs: { steppers: _vm.steppers, value: _vm.stepperValue },
                  on: {
                    change: function ($event) {
                      return _vm.goToNextZMode($event)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "v-form",
              {
                ref: "form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _vm.stepperValue === 1
                  ? _c("section", [
                      _c("div", { staticClass: "form-content" }, [
                        _c("div", { staticClass: "form-content__title" }, [
                          _c("span", [
                            _vm._v(
                              " Crie sua conexão ou escolha uma conexão previamente criada para segmentar "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-content__header" },
                          [
                            _c("z-select", {
                              attrs: {
                                placeholder: "Clique aqui para iniciar",
                                rules: [(v) => !!v || "Campo obrigatório"],
                                items: _vm.connections,
                                itemText: "name",
                                itemValue: "value",
                              },
                              model: {
                                value: _vm.dbConfig.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dbConfig, "type", $$v)
                                },
                                expression: "dbConfig.type",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.dbConfig.type
                          ? _c("div", { staticClass: "form-content__body" }, [
                              _c(
                                "div",
                                { staticClass: "form-content__body__elements" },
                                [
                                  _vm.dbConfig.type !== "csv"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-content__body__elements__left",
                                        },
                                        [
                                          _c("div", { staticClass: "title" }, [
                                            _c("span", [
                                              _vm._v(
                                                " Preencha os campos abaixo de acordo com o arquivo que foi selecionado: "
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "list" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "list__item__input",
                                                            },
                                                            [
                                                              _c("z-input", {
                                                                attrs: {
                                                                  rules: [
                                                                    (v) =>
                                                                      !!v ||
                                                                      "Campo obrigatório",
                                                                  ],
                                                                  hideDetails: true,
                                                                  placeholder:
                                                                    "Servidor de banco de dados",
                                                                  type: "text",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dbConfig
                                                                      .host,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.dbConfig,
                                                                        "host",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "dbConfig.host",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "list__item__input",
                                                            },
                                                            [
                                                              _c("z-input", {
                                                                attrs: {
                                                                  rules: [
                                                                    (v) =>
                                                                      !!v ||
                                                                      "Campo obrigatório",
                                                                  ],
                                                                  hideDetails: true,
                                                                  placeholder:
                                                                    "Schema",
                                                                  type: "text",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dbConfig
                                                                      .defaultSchema,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.dbConfig,
                                                                        "defaultSchema",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "dbConfig.defaultSchema",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "list__item__input",
                                                            },
                                                            [
                                                              _c("z-input", {
                                                                attrs: {
                                                                  rules: [
                                                                    (v) =>
                                                                      !!v ||
                                                                      "Campo obrigatório",
                                                                  ],
                                                                  hideDetails: true,
                                                                  placeholder:
                                                                    "Porta",
                                                                  type: "text",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dbConfig
                                                                      .port,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.dbConfig,
                                                                        "port",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "dbConfig.port",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "list__item__input",
                                                            },
                                                            [
                                                              _c("z-input", {
                                                                attrs: {
                                                                  rules: [
                                                                    (v) =>
                                                                      !!v ||
                                                                      "Campo obrigatório",
                                                                  ],
                                                                  hideDetails: true,
                                                                  placeholder:
                                                                    "Nome do  banco de dados",
                                                                  type: "text",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dbConfig
                                                                      .dbName,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.dbConfig,
                                                                        "dbName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "dbConfig.dbName",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "list__item__input",
                                                            },
                                                            [
                                                              _c("z-input", {
                                                                attrs: {
                                                                  rules: [
                                                                    (v) =>
                                                                      !!v ||
                                                                      "Campo obrigatório",
                                                                  ],
                                                                  hideDetails: true,
                                                                  placeholder:
                                                                    "Nome do usuário",
                                                                  type: "text",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dbConfig
                                                                      .user,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.dbConfig,
                                                                        "user",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "dbConfig.user",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "list__item__input",
                                                            },
                                                            [
                                                              _c("z-input", {
                                                                attrs: {
                                                                  rules: [
                                                                    (v) =>
                                                                      !!v ||
                                                                      "Campo obrigatório",
                                                                  ],
                                                                  hideDetails: true,
                                                                  placeholder:
                                                                    "Senha",
                                                                  type: "text",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dbConfig
                                                                      .pwd,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.dbConfig,
                                                                        "pwd",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "dbConfig.pwd",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "list__item--action",
                                                            },
                                                            [
                                                              _c("z-btn", {
                                                                attrs: {
                                                                  text: "Testar conexão",
                                                                  color:
                                                                    "primary",
                                                                  "is-loading":
                                                                    _vm.loadingIntegration,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.integrationTest,
                                                                },
                                                              }),
                                                              _c("z-btn", {
                                                                attrs: {
                                                                  isLoading:
                                                                    _vm.loadingConnect,
                                                                  text: "Conectar",
                                                                  primary: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.conect,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-content__body__elements__left",
                                        },
                                        [
                                          _c("div", { staticClass: "title" }, [
                                            _c("span", [
                                              _vm._v(
                                                " Utilize o botão abaixo para selecionar o arquivo desejado: "
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "list",
                                              staticStyle: {
                                                "padding-top": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list__item__input",
                                                    },
                                                    [
                                                      _c("z-btn", {
                                                        attrs: {
                                                          isLoading:
                                                            _vm.loadingUploadDocument,
                                                          text: "Escolher arquivo",
                                                          primary: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openFilePicker()
                                                          },
                                                        },
                                                      }),
                                                      _vm.selectedCSVFile
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "10px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedCSVFile
                                                                    .name
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("input", {
                                                        ref: "file",
                                                        staticStyle: {
                                                          display: "none",
                                                        },
                                                        attrs: {
                                                          type: "file",
                                                          accept: ".csv",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.previewFiles,
                                                        },
                                                      }),
                                                      _c("br"),
                                                      _c("br"),
                                                      _vm.csvArray &&
                                                      _vm.csvError
                                                        ? _c("z-btn", {
                                                            attrs: {
                                                              isLoading:
                                                                _vm.loadingUploadDocument,
                                                              text: "Tentar novamente",
                                                              primary: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.sendFile(
                                                                  _vm.csvArray
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm.showProgress
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Envio do Arquivo: " +
                                                                    _vm._s(
                                                                      _vm.uploadPercent
                                                                    ) +
                                                                    "%"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "z-linear-progress",
                                                                {
                                                                  staticStyle: {
                                                                    background:
                                                                      "lightgray",
                                                                    "border-radius":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    value:
                                                                      _vm.uploadPercent,
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                  _vm.dbConfig.type && _vm.tables.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-content__body__elements__right",
                                        },
                                        [
                                          _c("div", { staticClass: "title" }, [
                                            _c("span", [
                                              _vm._v(
                                                " Selecione a tabela que será utilizada: "
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "list" }, [
                                            _c(
                                              "div",
                                              { staticClass: "list__item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "list__item__input",
                                                  },
                                                  [
                                                    _c("z-autocomplete", {
                                                      attrs: {
                                                        items: _vm.tables,
                                                        itemText: "table",
                                                        hideDetails: true,
                                                        rules: [
                                                          (v) =>
                                                            !!v ||
                                                            "Campo obrigatório",
                                                        ],
                                                        placeholder: "Tabelas",
                                                        "return-object": "",
                                                      },
                                                      on: {
                                                        change: _vm.changeTable,
                                                      },
                                                      model: {
                                                        value: _vm.choosenTable,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.choosenTable = $$v
                                                        },
                                                        expression:
                                                          "choosenTable",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-content__body__elements__right",
                                        },
                                        [
                                          _c("div", { staticClass: "title" }, [
                                            _c("span"),
                                          ]),
                                          _c("div", { staticClass: "list" }, [
                                            _c(
                                              "div",
                                              { staticClass: "list__item" },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "list__item__input",
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm.stepperValue === 2
                  ? _c("section", [
                      _c("div", { staticClass: "card-item-step step-3" }, [
                        _c("div", { staticClass: "card-item-step__title" }, [
                          _c("span", [_vm._v(" Mapeamento de Dados ")]),
                        ]),
                        _c("div", { staticClass: "card-item-step__content" }, [
                          _c(
                            "div",
                            { staticClass: "table" },
                            [
                              _c("div", { staticClass: "table__header" }, [
                                _c(
                                  "div",
                                  { staticClass: "table__header__line" },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-header" },
                                        [_vm._v("Nome do campo")]
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-header" },
                                        [_vm._v("Coluna")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("v-divider", { staticClass: "my-3" }),
                              _c(
                                "div",
                                { staticClass: "table__body" },
                                _vm._l(
                                  _vm.dataMapperObjectFixed,
                                  function (item, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "table__body__line",
                                      },
                                      [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getCampName(i))),
                                          ]),
                                        ]),
                                        _vm.getCampName(i).toLowerCase() !=
                                        "telefone (opcional)"
                                          ? _c(
                                              "div",
                                              [
                                                _vm.dbConfig.type !== "csv"
                                                  ? _c("z-autocomplete", {
                                                      attrs: {
                                                        rules: [
                                                          (v) =>
                                                            i !== "id" ||
                                                            !!v ||
                                                            "Campo obrigatório",
                                                        ],
                                                        items: _vm.choosenTable
                                                          ?.fields.length
                                                          ? _vm.choosenTable
                                                              .fields
                                                          : [],
                                                        hideDetails: true,
                                                        itemText: "name",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .dataMapperObjectFixed[
                                                            i
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dataMapperObjectFixed,
                                                            i,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dataMapperObjectFixed[i]",
                                                      },
                                                    })
                                                  : _c("z-autocomplete", {
                                                      attrs: {
                                                        rules: [
                                                          (v) =>
                                                            i !== "id" ||
                                                            !!v ||
                                                            "Campo obrigatório",
                                                        ],
                                                        items:
                                                          _vm.CSVColumns !==
                                                          null
                                                            ? _vm.CSVColumns
                                                            : [],
                                                        itemText: "name",
                                                        hideDetails: true,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .dataMapperObjectFixed[
                                                            i
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dataMapperObjectFixed,
                                                            i,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dataMapperObjectFixed[i]",
                                                      },
                                                    }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c("z-btn", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    text:
                                                      !_vm.phoneCombinations
                                                        .length > 0 &&
                                                      !_vm.phoneColumnDataType
                                                        ?.length
                                                        ? "Configurar"
                                                        : "Configurado",
                                                    color:
                                                      !_vm.phoneCombinations
                                                        .length > 0 &&
                                                      !_vm.phoneColumnDataType
                                                        ?.length
                                                        ? "primary"
                                                        : "success",
                                                    rounded: true,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.phoneDialog = true
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.stepperValue === 3
                  ? _c("section", [
                      _c("div", { staticClass: "card-item-step step-3" }, [
                        _c("div", { staticClass: "card-item-step__title" }, [
                          _c("span", [_vm._v(" Mapeamento de Dados ")]),
                        ]),
                        _c("div", { staticClass: "card-item-step__content" }, [
                          _c(
                            "div",
                            { staticClass: "table" },
                            [
                              _c("div", { staticClass: "table__header" }, [
                                _c(
                                  "div",
                                  { staticClass: "table__header__line" },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-header" },
                                        [
                                          _c("z-checkbox", {
                                            attrs: {
                                              disabled:
                                                !_vm.dataMapperObjectModular
                                                  .length,
                                              hideDetails: true,
                                            },
                                            model: {
                                              value: _vm.checkAllData,
                                              callback: function ($$v) {
                                                _vm.checkAllData = $$v
                                              },
                                              expression: "checkAllData",
                                            },
                                          }),
                                          _vm._v(" Selec. Todos"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "hide" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-header",
                                          staticStyle: { width: "200px" },
                                        },
                                        [_vm._v("Nome do campo")]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "hide text-center",
                                        staticStyle: { width: "200px" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-header" },
                                          [_vm._v("Novo nome")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "hide text-center",
                                        staticStyle: { width: "200px" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-header" },
                                          [_vm._v("Tipo do campo")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "hide text-center" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-header" },
                                          [_vm._v("Formato do campo")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-divider", { staticClass: "my-3" }),
                              _vm.dataMapperObjectModular.length
                                ? _c(
                                    "div",
                                    { staticClass: "table__body" },
                                    [
                                      _vm._l(
                                        _vm.dataMapperObjectModular,
                                        function (item, i) {
                                          return [
                                            !_vm.itemIsSeted(
                                              item.name || item.item
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass:
                                                      "table__body__line flex-line",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("z-checkbox", {
                                                          attrs: {
                                                            hideDetails: true,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.addOrRemoveKeyOnMapper(
                                                                $event,
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item.active,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "active",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.active",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", [
                                                      _vm.dbConfig.type !==
                                                      "csv"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.item)
                                                            ),
                                                          ]),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("z-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "Digite aqui",
                                                            "hide-details": true,
                                                            type: "text",
                                                          },
                                                          model: {
                                                            value:
                                                              item.destiny_name,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "destiny_name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.destiny_name",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        item.active
                                                          ? _c("z-select", {
                                                              attrs: {
                                                                placeholder:
                                                                  "Tipo do campo",
                                                                itemText:
                                                                  "name",
                                                                itemValue:
                                                                  "value",
                                                                "hide-details": true,
                                                                items:
                                                                  _vm.dataTypes,
                                                                rules: [
                                                                  (v) =>
                                                                    !!v ||
                                                                    "Campo obrigatório",
                                                                ],
                                                                type: "text",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.dataType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "dataType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.dataType",
                                                              },
                                                            })
                                                          : _c("z-select", {
                                                              attrs: {
                                                                items:
                                                                  _vm.dataTypes,
                                                                itemText:
                                                                  "name",
                                                                itemValue:
                                                                  "value",
                                                                placeholder:
                                                                  "Tipo do campo",
                                                                "hide-details": true,
                                                                type: "text",
                                                                readonly: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.dataType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "dataType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.dataType",
                                                              },
                                                            }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                      },
                                                      [
                                                        item.dataType ===
                                                        "datetime"
                                                          ? _c("z-select", {
                                                              attrs: {
                                                                rules: [
                                                                  (v) =>
                                                                    !!v ||
                                                                    "Campo obrigatório",
                                                                ],
                                                                items:
                                                                  _vm.dateTimeFormats,
                                                                itemText:
                                                                  "format",
                                                                itemValue:
                                                                  "format",
                                                                placeholder:
                                                                  "Formato do campo",
                                                                "hide-details": true,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.format,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "format",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.format",
                                                              },
                                                            })
                                                          : item.dataType ===
                                                            "date"
                                                          ? _c("z-select", {
                                                              attrs: {
                                                                rules: [
                                                                  (v) =>
                                                                    !!v ||
                                                                    "Campo obrigatório",
                                                                ],
                                                                items:
                                                                  _vm.dateFormats,
                                                                itemText:
                                                                  "format",
                                                                itemValue:
                                                                  "format",
                                                                placeholder:
                                                                  "Formato do campo",
                                                                "hide-details": true,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.format,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "format",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.format",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _c("div", [
                                    _c("strong", [
                                      _vm._v("Informções indisponíveis"),
                                    ]),
                                  ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.stepperValue === 4
                  ? _c("section", [
                      _c("div", { staticClass: "card-item-step" }, [
                        _c("div", { staticClass: "card-item-step__title" }, [
                          _c("span", [_vm._v(" Atualização ")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-item-step__content my-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-item-step__content__information",
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "12",
                                          md: "3",
                                          lg: "3",
                                        },
                                      },
                                      [
                                        _c("z-select", {
                                          attrs: {
                                            label: "Comportamento de erro",
                                            items: _vm.errorBehaviourItems,
                                            rules: [
                                              (v) => !!v || "Campo obrigatório",
                                            ],
                                            hideDetails: true,
                                            itemText: "name",
                                            itemValue: "value",
                                          },
                                          model: {
                                            value: _vm.errorBehaviour,
                                            callback: function ($$v) {
                                              _vm.errorBehaviour = $$v
                                            },
                                            expression: "errorBehaviour",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "12",
                                          md: "3",
                                          lg: "3",
                                        },
                                      },
                                      [
                                        _c("z-select", {
                                          attrs: {
                                            label: "Frequência de atualização",
                                            items:
                                              _vm.dbConfig.type !== "csv"
                                                ? _vm.frequencies
                                                : _vm.frequencies_csv,
                                            rules: [
                                              (v) => !!v || "Campo obrigatório",
                                            ],
                                            hideDetails: true,
                                            itemText: "name",
                                            itemValue: "value",
                                          },
                                          model: {
                                            value: _vm.cron,
                                            callback: function ($$v) {
                                              _vm.cron = $$v
                                            },
                                            expression: "cron",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-row"),
                                _vm.dbConfig.type !== "csv" &&
                                _vm.cron &&
                                _vm.cron != "not" &&
                                _vm.cron != "not-api"
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "12",
                                              md: "3",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("z-autocomplete", {
                                              attrs: {
                                                label: "Campo de controle",
                                                items: _vm.choosenTable?.fields
                                                  .length
                                                  ? _vm.choosenTable.fields
                                                  : [],
                                                rules: [
                                                  (v) =>
                                                    !!v || "Campo obrigatório",
                                                ],
                                                hideDetails: true,
                                                itemText: "name",
                                                itemValue: "name",
                                              },
                                              model: {
                                                value: _vm.controlColumn,
                                                callback: function ($$v) {
                                                  _vm.controlColumn = $$v
                                                },
                                                expression: "controlColumn",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.hasSelectedSkuId()
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "12",
                                              md: "3",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("z-select", {
                                              attrs: {
                                                label: "Tipo do SKU",
                                                items: [
                                                  {
                                                    name: "token",
                                                    value: "token",
                                                  },
                                                  {
                                                    name: "tópico",
                                                    value: "topic",
                                                  },
                                                ],
                                                rules: [
                                                  (v) =>
                                                    !!v || "Campo obrigatório",
                                                ],
                                                hideDetails: true,
                                                itemText: "name",
                                                itemValue: "value",
                                              },
                                              model: {
                                                value: _vm.skuType,
                                                callback: function ($$v) {
                                                  _vm.skuType = $$v
                                                },
                                                expression: "skuType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "wrapper__card__stepper--actions" },
              [
                _vm.stepperValue > 1
                  ? _c("z-btn", {
                      attrs: {
                        text: "Voltar",
                        color: "primary",
                        rounded: true,
                      },
                      on: {
                        click: function ($event) {
                          _vm.stepperValue > 1 ? _vm.stepperValue-- : ""
                        },
                      },
                    })
                  : _c("span"),
                _c("z-btn", {
                  attrs: {
                    text: _vm.stepperValue < 4 ? "Prosseguir" : "Finalizar",
                    primary: "",
                    color: "primary",
                    rounded: true,
                    disabled: !_vm.canGoFoward,
                    "is-loading": !_vm.canGoFoward && _vm.stepperValue >= 4,
                  },
                  on: { click: _vm.goToNext },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "formDialog",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveAndClose.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "wrapper__dialog" }, [
                _c("div", { staticClass: "wrapper__dialog__title" }, [
                  _c("span", [_vm._v("Insira o nome da configuração")]),
                ]),
                _c(
                  "div",
                  { staticClass: "wrapper__dialog__form" },
                  [
                    _c("z-input", {
                      attrs: {
                        hideDetails: false,
                        rules: [(v) => !!v || "Campo obrigatório"],
                        placeholder: "Nome",
                        type: "text",
                      },
                      model: {
                        value: _vm.configName,
                        callback: function ($$v) {
                          _vm.configName = $$v
                        },
                        expression: "configName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wrapper__dialog--actions" },
                  [
                    _c("z-btn", {
                      attrs: {
                        text: "Cancelar",
                        color: "primary",
                        rounded: true,
                      },
                      on: { click: _vm.cancelAndReset },
                    }),
                    _c("z-btn", {
                      attrs: {
                        text: "Salvar",
                        primary: "",
                        color: "primary",
                        rounded: true,
                        "is-loading": _vm.loadingModal,
                      },
                      on: { click: _vm.saveAndClose },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.phoneDialog,
            callback: function ($$v) {
              _vm.phoneDialog = $$v
            },
            expression: "phoneDialog",
          },
        },
        [
          _c(
            "div",
            { staticClass: "wrapper__phone-dialog" },
            [
              _c(
                "div",
                { staticClass: "wrapper__phone-dialog__descriptions" },
                [
                  _c("span", [
                    _vm._v("Configuração avançada do cliente de dados"),
                  ]),
                  _c("p", [
                    _vm._v(
                      " Você pode criar configurações mais detalhadas sobre os dados, como quando existir mais de uma fonte de dados para o campo escolhido. "
                    ),
                    _c("br"),
                    _c("small", { staticStyle: { "font-size": "0.71rem" } }, [
                      _vm._v(
                        "*Necessário mapear ao menos os campos: DDI, DDD e Telefone"
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("v-form", { ref: "phoneForm" }, [
                _c(
                  "div",
                  { staticClass: "wrapper__phone-dialog__main-line mb-2" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "wrapper__phone-dialog__main-line__content item-columns",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left" },
                          [
                            _c("z-select", {
                              staticClass: "item",
                              attrs: {
                                label: "Modelo de dados",
                                items: _vm.mainDataModels,
                                rules: [(v) => !!v || "Campo obrigatório"],
                                itemText: "name",
                                itemValue: "value",
                                hideDetails: true,
                              },
                              model: {
                                value: _vm.dataModel,
                                callback: function ($$v) {
                                  _vm.dataModel = $$v
                                },
                                expression: "dataModel",
                              },
                            }),
                            _c("z-select", {
                              staticClass: "item",
                              attrs: {
                                label: "Selecionar o tipo",
                                rules: [(v) => !!v || "Campo obrigatório"],
                                items:
                                  _vm.dataModel === "cod-pais"
                                    ? _vm.selectTypeCountrie
                                    : _vm.dataModel === "cod-uf"
                                    ? _vm.selectTypeState
                                    : _vm.selectJustColumn,
                                itemText: "name",
                                itemValue: "value",
                                hideDetails: true,
                              },
                              model: {
                                value: _vm.phoneDataType,
                                callback: function ($$v) {
                                  _vm.phoneDataType = $$v
                                },
                                expression: "phoneDataType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "middle" }, [
                          _c("img", {
                            attrs: { src: "/icons/arrow-right-icon.svg" },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c("z-autocomplete", {
                              attrs: {
                                label: _vm.phoneLabelsType[_vm.phoneDataType],
                                rules: [(v) => !!v || "Campo obrigatório"],
                                items:
                                  _vm.phoneDataType === "ddi"
                                    ? _vm.countries
                                    : _vm.phoneDataType === "ddd"
                                    ? _vm.dddList
                                    : _vm.dbConfig.type !== "csv" &&
                                      _vm.choosenTable?.fields.length
                                    ? _vm.choosenTable?.fields
                                    : _vm.dbConfig.type == "csv" &&
                                      _vm.CSVColumns !== null
                                    ? _vm.CSVColumns
                                    : [],
                                itemText:
                                  _vm.phoneDataType === "ddi"
                                    ? "pais"
                                    : _vm.phoneDataType === "ddd"
                                    ? "location"
                                    : _vm.dbConfig.type !== "csv"
                                    ? "name"
                                    : "name",
                                itemValue:
                                  _vm.phoneDataType === "ddi"
                                    ? "ddi"
                                    : _vm.phoneDataType === "ddd"
                                    ? "ddd"
                                    : _vm.dbConfig.type !== "csv" &&
                                      _vm.choosenTable?.fields.length
                                    ? "name"
                                    : _vm.dbConfig.type == "csv" &&
                                      _vm.CSVColumns !== null
                                    ? "name"
                                    : "name",
                                hideDetails: true,
                              },
                              model: {
                                value: _vm.phoneColumnDataType,
                                callback: function ($$v) {
                                  _vm.phoneColumnDataType = $$v
                                },
                                expression: "phoneColumnDataType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "action" }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "wrapper__phone-dialog__list" },
                  _vm._l(_vm.phoneCombinations, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass:
                          "wrapper__phone-dialog__list__item mb-2 item-columns",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left" },
                          [
                            _c("z-select", {
                              staticClass: "item",
                              attrs: {
                                label: "Modelo de dados",
                                items: _vm.mainDataModels,
                                rules: [(v) => !!v || "Campo obrigatório"],
                                itemText: "name",
                                itemValue: "value",
                                hideDetails: true,
                              },
                              model: {
                                value: item.data_model,
                                callback: function ($$v) {
                                  _vm.$set(item, "data_model", $$v)
                                },
                                expression: "item.data_model",
                              },
                            }),
                            _c("z-select", {
                              staticClass: "item",
                              attrs: {
                                label: "Selecionar o tipo",
                                rules: [(v) => !!v || "Campo obrigatório"],
                                items:
                                  item.data_model === "cod-pais"
                                    ? _vm.selectTypeCountrie
                                    : item.data_model === "cod-uf"
                                    ? _vm.selectTypeState
                                    : _vm.selectJustColumn,
                                itemText: "name",
                                itemValue: "value",
                                hideDetails: true,
                                readonly: !_vm.isLastOption(item),
                              },
                              model: {
                                value: item.data_type,
                                callback: function ($$v) {
                                  _vm.$set(item, "data_type", $$v)
                                },
                                expression: "item.data_type",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "middle" }, [
                          _c("img", {
                            attrs: { src: "/icons/arrow-right-icon.svg" },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c("z-autocomplete", {
                              attrs: {
                                label: _vm.phoneLabelsType[item.data_type],
                                rules: [(v) => !!v || "Campo obrigatório"],
                                items:
                                  item.data_type === "ddi"
                                    ? _vm.countries
                                    : item.data_type === "ddd"
                                    ? _vm.dddList
                                    : _vm.dbConfig.type !== "csv" &&
                                      _vm.choosenTable?.fields.length
                                    ? _vm.choosenTable?.fields
                                    : _vm.dbConfig.type == "csv" &&
                                      _vm.CSVColumns !== null
                                    ? _vm.CSVColumns
                                    : [],
                                itemText:
                                  item.data_type === "ddi"
                                    ? "pais"
                                    : item.data_type === "ddd"
                                    ? "location"
                                    : _vm.dbConfig.type !== "csv"
                                    ? "name"
                                    : "name",
                                itemValue:
                                  item.data_type === "ddi"
                                    ? "ddi"
                                    : item.data_type === "ddd"
                                    ? "ddd"
                                    : _vm.dbConfig.type !== "csv" &&
                                      _vm.choosenTable?.fields.length
                                    ? "name"
                                    : _vm.dbConfig.type == "csv" &&
                                      _vm.CSVColumns !== null
                                    ? "name"
                                    : "name",
                                hideDetails: true,
                                readonly: !_vm.isLastOption(item),
                              },
                              model: {
                                value: item.table_column,
                                callback: function ($$v) {
                                  _vm.$set(item, "table_column", $$v)
                                },
                                expression: "item.table_column",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("figure", { staticClass: "action" }, [
                          _vm.isLastOption(item)
                            ? _c("img", {
                                attrs: { src: "/icons/minus-circle-icon.svg" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removePhoneCombination(item)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "wrapper__phone-dialog--add-combination d-flex justify-center",
                },
                [
                  _c("z-btn", {
                    attrs: {
                      icon: "mdi-plus",
                      text: "adicionar mais uma combinação",
                      primary: "",
                      color: "primary",
                    },
                    on: { click: _vm.addPhoneCombination },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "wrapper__phone-dialog--actions mt-2" },
                [
                  _c("z-btn", {
                    attrs: { text: "Cancelar", color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.resetPhoneDialogAndClose()
                      },
                    },
                  }),
                  _c("z-btn", {
                    attrs: {
                      text: "Salvar configuração",
                      primary: "",
                      color: "primary",
                    },
                    on: { click: _vm.closePhoneModal },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }